import React, { useState, useEffect, useRef } from 'react'
import { Layouts, Timers } from './components'

function calcTime() {
	const date1 = new Date('2021-09-13T00:00:00');
	const date2 = new Date()
	// Ensure date1 is before date2
	if (date1 > date2) {
		[date1, date2] = [date2, date1];
	}

	let years = date2.getFullYear() - date1.getFullYear();
	let months = date2.getMonth() - date1.getMonth();
	let days = date2.getDate() - date1.getDate();
	let hours = date2.getHours() - date1.getHours();
	let minutes = date2.getMinutes() - date1.getMinutes();
	let seconds = date2.getSeconds() - date1.getSeconds();

	// Adjust for negative values
	if (seconds < 0) {
		seconds += 60;
		minutes--;
	}

	if (minutes < 0) {
		minutes += 60;
		hours--;
	}

	if (hours < 0) {
		hours += 24;
		days--;
	}

	if (days < 0) {
		const previousMonth = new Date(date2.getFullYear(), date2.getMonth(), 0);
		days += previousMonth.getDate();
		months--;
	}

	if (months < 0) {
		months += 12;
		years--;
	}

	return {
		years: years,
		months: months,
		days: days,
		hours: hours,
		minutes: minutes,
		seconds: seconds
	};
}

export default function App() {
	const [timer, setTimer] = useState(calcTime())

	useEffect(() => {
		setInterval(function () {
			setTimer(calcTime())
		}, 1000)
	}, [])

	return <Layouts.Container
		className={'flex items-center justify-center bg-cover bg-center'}
		style={{ backgroundImage: 'url(\'/img/DSC04095.jpg\')' }}
	>
		<div className={'z-20'}>
			<Timers.Container>
				<Timers.Item label={'Years'}>{timer.years}</Timers.Item>
				<Timers.Item label={'Months'}>{timer.months}</Timers.Item>
				<Timers.Item label={'Days'}>{timer.days}</Timers.Item>
				<Timers.Item label={'Hours'}>{timer.hours}</Timers.Item>
				<Timers.Item label={'Minutes'}>{timer.minutes}</Timers.Item>
				<Timers.Item label={'Seconds'}>{timer.seconds}</Timers.Item>
			</Timers.Container>
		</div>
	</Layouts.Container>
}
